/* eslint-disable no-unused-vars */
import React, { Suspense, Fragment, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';
import { auth } from './helpers/authHelper';
import { BASE_URL } from './config/constant';
import Signin1 from './views/auth/signin/SignIn1';
import NotFound from './views/NotFound/NotFound';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Routes>
      {auth() ? (
        routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Element = route.element;

          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Guard>
                  <Layout>{route.routes ? renderRoutes(route.routes) : <Element props={true} />}</Layout>
                </Guard>
              }
            />
          );
        })
      ) : (
        <>
          <Route path="/signin" Component={Signin1} />
        </>
      )}
      <Route path="*" Component={NotFound} />
    </Routes>
  </Suspense>
);

const routes = [
  {
    path: '*',
    layout: AdminLayout,
    routes: [
      {
        exact: 'true',
        path: '/app/dashboard/default',
        element: lazy(() => import('./views/dashboard'))
      },
      {
        exact: 'true',
        path: '/basic/button',
        element: lazy(() => import('./views/ui-elements/basic/BasicButton'))
      },
      {
        exact: 'true',
        path: '/basic/badges',
        element: lazy(() => import('./views/ui-elements/basic/BasicBadges'))
      },
      {
        exact: 'true',
        path: '/basic/breadcrumb',
        element: lazy(() => import('./views/ui-elements/basic/BasicBreadcrumb'))
      },
      {
        exact: 'true',
        path: '/basic/pagination',
        element: lazy(() => import('./views/ui-elements/basic/BasicPagination'))
      },
      {
        exact: 'true',
        path: '/basic/collapse',
        element: lazy(() => import('./views/ui-elements/basic/BasicCollapse'))
      },
      {
        exact: 'true',
        path: '/basic/tabs-pills',
        element: lazy(() => import('./views/ui-elements/basic/BasicTabsPills'))
      },
      {
        exact: 'true',
        path: '/basic/typography',
        element: lazy(() => import('./views/ui-elements/basic/BasicTypography'))
      },
      {
        exact: 'true',
        path: '/forms/form-basic',
        element: lazy(() => import('./views/forms/FormsElements'))
      },
      {
        exact: 'true',
        path: '/forms/AddUserForm',
        element: lazy(() => import('./views/tables/Functions/AddUserForm'))
      },
      {
        exact: 'true',
        path: '/forms/UpdateForm',
        element: lazy(() => import('./views/tables/Functions/UpdateUserForm'))
      },
      {
        exact: 'true',
        path: '/tables/bootstrap',
        element: lazy(() => import('./views/tables/BootstrapTable'))
      },
      {
        exact: 'true',
        path: '/hoverTables/bootstrap',
        element: lazy(() => import('./views/tables/HoverTable'))
      },
      {
        exact: 'true',
        path: '/charts/nvd3',
        element: lazy(() => import('./views/charts/nvd3-chart'))
      },
      {
        exact: 'true',
        path: '/masters/users',
        element: lazy(() => import('./components/users/User'))
      },
      {
        exact: 'true',
        path: '/user/info/:id',
        element: lazy(() => import('./components/users/Information'))
      },
      {
        exact: 'true',
        path: '/masters/city',
        element: lazy(() => import('./components/city/City'))
      },
      {
        exact: 'true',
        path: '/masters/AddCity',
        element: lazy(() => import('./components/city/AddCity'))
      },
      {
        exact: 'true',
        path: '/masters/EditCity/:id',
        element: lazy(() => import('./components/city/EditCity'))
      },
      {
        exact: 'true',
        path: '/masters/state',
        element: lazy(() => import('./components/state/State'))
      },
      {
        exact: 'true',
        path: '/masters/AddState',
        element: lazy(() => import('./components/state/AddState'))
      },
      {
        exact: 'true',
        path: '/masters/EditState/:id',
        element: lazy(() => import('./components/state/EditState'))
      },
      {
        exact: 'true',
        path: '/masters/country',
        element: lazy(() => import('./components/country/Country'))
      },
      {
        exact: 'true',
        path: '/masters/AddCountry',
        element: lazy(() => import('./components/country/AddCountry'))
      },
      {
        exact: 'true',
        path: '/masters/EditCountry/:id',
        element: lazy(() => import('./components/country/EditCountry'))
      },
      {
        exact: 'true',
        path: '/masters/education',
        element: lazy(() => import('./components/education/Education'))
      },
      {
        exact: 'true',
        path: '/masters/AddEducation',
        element: lazy(() => import('./components/education/AddEducation'))
      },
      {
        exact: 'true',
        path: '/masters/EditEducation/:id',
        element: lazy(() => import('./components/education/EditEducation'))
      },
      {
        exact: 'true',
        path: '/masters/interest',
        element: lazy(() => import('./components/interest/Interest'))
      },
      {
        exact: 'true',
        path: '/masters/AddInterest',
        element: lazy(() => import('./components/interest/AddInterest'))
      },
      {
        exact: 'true',
        path: '/masters/EditInterest/:id',
        element: lazy(() => import('./components/interest/EditInterest'))
      },
      {
        exact: 'true',
        path: '/masters/language',
        element: lazy(() => import('./components/language/Language'))
      },
      {
        exact: 'true',
        path: '/masters/AddLanguage',
        element: lazy(() => import('./components/language/AddLanguage'))
      },
      {
        exact: 'true',
        path: '/masters/EditLanguage/:id',
        element: lazy(() => import('./components/language/EditLanguage'))
      },
      {
        exact: 'true',
        path: '/masters/skills',
        element: lazy(() => import('./components/skills/Skills'))
      },
      {
        exact: 'true',
        path: '/masters/AddSkills',
        element: lazy(() => import('./components/skills/AddSkills'))
      },
      {
        exact: 'true',
        path: '/masters/EditSkills/:id',
        element: lazy(() => import('./components/skills/EditSkills'))
      },
      {
        exact: 'true',
        path: '/masters/university',
        element: lazy(() => import('./components/university/University'))
      },
      {
        exact: 'true',
        path: '/masters/AddUniversity',
        element: lazy(() => import('./components/university/AddUniversity'))
      },
      {
        exact: 'true',
        path: '/masters/EditUniversity/:id',
        element: lazy(() => import('./components/university/EditUniversity'))
      },
      {
        exact: 'true',
        path: '/masters/TradeType',
        element: lazy(() => import('./components/tradeType/TypeTrade'))
      },
      {
        exact: 'true',
        path: '/masters/AddTradeType',
        element: lazy(() => import('./components/tradeType/AddTradeType'))
      },
      {
        exact: 'true',
        path: '/masters/EditTradeType/:id',
        element: lazy(() => import('./components/tradeType/EditTradeType'))
      },
      {
        exact: 'true',
        path: '/masters/CatTradeType',
        element: lazy(() => import('./components/catTradeType/CatTradeType'))
      },
      {
        exact: 'true',
        path: '/masters/EditCatTradeType/:id',
        element: lazy(() => import('./components/catTradeType/EditCatTradeType'))
      },
      {
        exact: 'true',
        path: '/masters/AddCatTradeType',
        element: lazy(() => import('./components/catTradeType/AddCatTradeType'))
      },
      {
        exact: 'true',
        path: '/masters/SubCatTradeType',
        element: lazy(() => import('./components/subCatTradeType/SubCatTradeType'))
      },
      {
        exact: 'true',
        path: '/masters/EditSubCatTradeType/:id',
        element: lazy(() => import('./components/subCatTradeType/EditSubCatTradeType'))
      },
      {
        exact: 'true',
        path: '/masters/AddSubCatTradeType',
        element: lazy(() => import('./components/subCatTradeType/AddSubCatTradeType'))
      },
      {
        exact: 'true',
        path: '/sample-page',
        element: lazy(() => import('./views/extra/SamplePage'))
      },
      {
        exact: 'true',
        path: '/report',
        element: lazy(() => import('./components/report/Report'))
      },
      {
        exact: 'true',
        path: '/report/addReport',
        element: lazy(() => import('./components/report/AddReport'))
      },
      {
        exact: 'true',
        path: '/report/editReport/:id',
        element: lazy(() => import('./components/report/EditReason'))
      },
      {
        exact: 'true',
        path: '/issuereport',
        element: lazy(() => import('./components/IssueReport/IssueReport'))
      },
      {
        exact: 'true',
        path: '/issueDetails/:id',
        element: lazy(() => import('./components/IssueReport/IssueDetails'))
      },
      {
        exact: 'true',
        path: '/project',
        element: lazy(() => import('./components/project/ProjectList'))
      },
      {
        exact: 'true',
        path: '/projectDetails/:id',
        element: lazy(() => import('./components/project/ProjectDetails'))
      },

      {
        exact: 'true',
        path: '/test',
        element: lazy(() => import('./components/test/Test'))
      },

      {
        exact: 'true',
        path: '/setting',
        element: lazy(() => import('./components/settings/Settings'))
      },

      // {
      //   exact: 'true',
      //   path: '/sub-testing/AddSubTesting',
      //   element: lazy(() => import('./components/subTesting/AddSubTesting'))
      // },
      // {
      //   exact: 'true',
      //   path: '/sub-testing/EditSubTesting/:id',
      //   element: lazy(() => import('./components/subTesting/EditSubTesting'))
      // },
      {
        exact: 'true',
        path: '/AI/all-entry',
        element: lazy(() => import('./components/AI/AllEntry'))
      },
      {
        exact: 'true',
        path: '/AI/AddEntry',
        element: lazy(() => import('./components/AI/AddEntry'))
      },
      {
        exact: 'true',
        path: '/AI/EditEntry/:id',
        element: lazy(() => import('./components/AI/EditEntry'))
      },

      {
        path: '*',
        exact: 'true',
        element: () => <Navigate to={BASE_URL} />
      }
    ]
  }
];

export default routes;
