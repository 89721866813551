import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div>
      404 NotFound!
      <Link to={'/signin'}>Please Log In</Link>
    </div>
  );
}

export default NotFound;
