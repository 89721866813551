/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Card, Button, Col, Row, Modal } from 'react-bootstrap';
import Breadcrumb from '../../../layouts/AdminLayout/Breadcrumb';
import axios from 'axios';
import NotFound from '../../NotFound/NotFound';
import { Urls } from '../../../helpers/Urls';

const Signin1 = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});

  const login = (e) => {
    e.preventDefault();
    axios
      .post(Urls.post.userLogin, payload)
      .then((response) => {
        setData(response.data);
        {
          response.data?.token ? setShow(false) : setShow(true);
        }

        const data = response.data;
        if (data.token) {
          const token = data.token;
          localStorage.setItem('token', token);
          localStorage.setItem('user', data.data._id);
          window.location.href = '/app/dashboard/default';
        }
      })
      .catch((error) => {
        return <NotFound />;
      });
  };

  const payload = {
    email: email,
    password: password
  };

  return (
    <React.Fragment>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Card className="borderless text-center">
            <Card.Body>
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon" />
              </div>
              <form>
                <div className="form-group mb-3">
                  <input
                    required={true}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="form-control"
                    label="Email Address / Username"
                    name="email"
                    type="email"
                    placeholder="example@gmail.com"
                  />
                </div>

                <div className="form-group mb-4">
                  <input
                    required={true}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="form-control"
                    placeholder="password"
                    name="password"
                    type="password"
                    disabled={email.length !== 0 ? false : true}
                  />
                </div>

                <Row>
                  <Col mt={2}>
                    <Button
                      onClick={login}
                      className="btn-block"
                      color="primary"
                      size="large"
                      type="submit"
                      variant="primary"
                      disabled={password.length !== 0 ? false : true}
                    >
                      Signin
                    </Button>
                  </Col>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </div>
        <Modal size="sm" show={show} onHide={() => setShow(false)} aria-labelledby="example-modal-sizes-title-sm">
          <Modal.Header closeButton>
            <b>{data?.message}</b>
          </Modal.Header>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Signin1;
